@import './assets/fonts/stylesheet.css';

:root {
  /* TODO: change based on figma file */
  background-color: black;
  color: white;
  --primary: #2a5c65;
  --primary-light: #4494a2;
  --secondary-bg: #0f0f0f;
  --accent: #1f1f1f;
  --offwhite: #b3b3b3;
  --tertiary-bg: #1a1a1a;
  --lightgray: #3a3a3a;
  --mgvwidth: 600px;
  line-height: 150%;
  letter-spacing: 0.015em;
  font-family: 'ProximaNovaRegular', Helvetica, sans-serif !important;
  font-weight: 400;
  text-align: left;
  text-justify: newspaper;
  /* word-break: normal;
  hyphens: auto;
  -webkit-hyphens: auto; */
  /*semi-bold for figma. 600 wasn't looking right.*/
}

.fw-bold {
  font-weight: 550;
}

/*figma: bold*/
.fw-bolder {
  font-weight: 600;
}

@media (max-width: 800px) {
  :root {
    font-size: 4vw;
  }

  .fs-smaller {
    font-size: 0.6rem;
  }

  /*figma: 14*/
  .fs-small {
    font-size: 0.8rem;
  }

  /*figma: 16*/
  .fs-medium {
    font-size: 1rem;
  }

  /*figma: 20*/
  .fs-large {
    font-size: 1.5rem;
  }

  .fs-larger {
    font-size: 1.67rem;
  }

  /*figma: 64*/
  .fs-x-large {
    font-size: 2rem;
  }

  /*figma: 80*/
  .fs-xx-large {
    font-size: 2.5rem;
  }

  /*figma: 96*/
}

@media (min-width: 800px) {
  :root {
    font-size: 1.275vw;
  }

  .fs-smaller {
    font-size: 0.7rem;
  }

  /*figma: 14*/
  .fs-small {
    font-size: 0.8rem;
  }

  /*figma: 16*/
  .fs-medium {
    font-size: 1rem;
  }

  /*figma: 20*/
  .fs-large {
    font-size: 1.6rem;
  }

  /*figma: 32*/
  .fs-larger {
    font-size: 3.2rem;
  }

  /*figma: 64*/
  .fs-x-large {
    font-size: 4rem;
  }

  /*figma: 80*/
  .fs-xx-large {
    font-size: 4.8rem;
  }

  /*figma: 96*/
}

.bg2 {
  background-color: var(--secondary-bg);
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: white;
  /* color: var(--accent); */
}
div.textonly > * > p {
  padding-top: 0px;
}
div.textonly > * > div {
  text-indent: 0;
  margin-block: 5px;
}
li {
  text-indent: 0;
  font-weight: 700;
}
div.main {
  margin-top: 15vh;
  margin-bottom: 5vh;
  margin-inline: 0px;
}

div.home > * {
  margin-inline: auto;
}

a.headerButton {
  color: white;
  border-radius: 5px;
  padding-block: 0.76vw;
  padding-inline: 1.2vw;
  letter-spacing: 0.1em;
}
a.hoverColor:hover {
  color: var(--primary-light);
}
a.hoverUnderline:hover {
  border-bottom: solid 3px var(--primary);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
a.button {
  color: white;
  border-radius: 5px;
  letter-spacing: 50%;
}

div.inline-fade {
  height: 300px;
  position: relative;
  z-index: 0;
}

div.inline-fade::before {
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2; /*huge to ensure overlay over most elements*/
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 1)
  );
}

p.shady-70 {
  opacity: 70%;
}

.bg-accent {
  background-color: var(--accent);
}

.accent {
  color: var(--accent);
}

.offwhite {
  color: var(--offwhite);
}

.title {
  line-height: 104%;
  text-align: center;
  font-weight: bold;
  padding: 0;
  margin: 0;
}

.title2 {
  line-height: 100%;
  font-weight: bold;
  text-align: center;
  padding: 0;
  margin: 0;
}

.subtitle {
  line-height: 104%;
  padding: 0;
  margin: 0;
}

a.footerLink {
  display: block;
  color: white;
  line-height: 150% !important;
  padding-block: 7px;
}

.col {
  display: flex;
  flex-direction: column;
}

.centercol {
  align-items: center;
}

.centerrow {
  justify-content: center;
}

.row {
  display: flex;
  flex-direction: row;
}

.rrow {
  display: flex;
  flex-direction: row-reverse;
}

/* input{
  height: 30px;
  border: 3px solid black;
  padding: 5px;
  border-radius: 5px;
} */
img.profile {
  border-radius: 28px;
  width: 56px;
  height: 56px;
  margin-right: 16px;
}

div.blogtile {
  height: 250px;
  overflow: hidden;
}

div.episodetile {
  height: 230px;
  overflow: hidden;
}

.student-img {
  width: 250px;
  height: 250px;
}

.person-img {
  width: 200px;
  height: 200px;
}

.preview-img {
  width: 30px;
  height: 30px;
}

hr.thick {
  border: 0;
  clear: both;
  display: block;
  width: 100%;
  background-color: var(--secondary-bg);
  height: 5px;
}

hr.thin {
  border: 0;
  clear: both;
  display: block;
  width: 100%;
  background-color: var(--secondary-bg);
  height: 1px;
}

p.stripped {
  margin: 0;
  padding: 0;
}

.thin-bordered {
  border: 0.5px solid var(--accent);
}

.titlebar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

a.button2 {
  background-color: var(--primary);
  padding-inline: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
}

a.button4 {
  background-color: var(--primary);
  padding-inline: 24px;
  padding-block: 18.5px;
  border-radius: 5px;
}

a.button3 {
  background-color: rgba(255, 255, 255, 0.1);
  padding-inline: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 20px;
}

.centered {
  text-align: center;
}

.gradient-shade {
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0)
  );
}

.left {
  text-align: left;
}

a.button-pill {
  border-radius: 30px;
  background-color: var(--tertiary-bg);
  padding: 8px;
  padding-inline: 16px;
  text-transform: uppercase;
}

.clocked::before {
  content: url('assets/clock.svg');
  position: relative;
  display: inline-block;
  vertical-align: middle;
  right: 0px;
  margin: 0px;
  padding-right: 8px;
  align-self: center;
}

a.caret-right::after {
  content: url('assets/caretRight.svg');
  position: relative;
  display: inline-block;
  vertical-align: middle;
  right: 0px;
  margin: 0px;
  padding-left: 4px;
  align-self: center;
}

/* 
TODO: employ only fs classes to set font sizes across elements.
add media query for all fs-clases' font-size values.
*/

.pointable {
  cursor: pointer;
}

.desktop {
  display: none;
}

@media (min-width: 800px) {
  .desktop {
    display: block;
  }

  .mobile {
    display: none;
  }
}

.shadow {
  box-shadow: 3px 3px 5px 5px grey;
}

.titlebar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

img.logo {
  width: 100px;
  height: 100px;
  padding: 10px;
}

div.grid {
  display: grid;
}

/* dimensions: 3 x n */
div.d3xn {
  grid-template-columns: 33% 33% 33%;
  align-items: center;
}

div.d2xn {
  grid-template-columns: 50% 50%;
  align-items: center;
}

.articlesgrid {
  display: grid;
  grid-template-columns: 100%;
  align-items: center;
}

@media (min-width: 750px) {
  .articlesgrid {
    grid-template-columns: 50% 50%;
  }
}

@media (min-width: 1200px) {
  .articlesgrid {
    grid-template-columns: 33% 33% 33%;
  }
}

.mentionsgrid {
  display: grid;
  grid-template-columns: 100%;
  align-items: center;
}

@media (min-width: 1200px) {
  .mentionsgrid {
    grid-template-columns: 50% 50%;
  }
}

.field.title {
  margin-top: 32px;
  margin-bottom: 0px;
  text-align: start;
}

.field.input {
  resize: none;
  position: relative;
  left: 0;
  right: 0;
  font-family: 'Proxima Nova Rg', Helvetica, sans-serif;
  margin-top: 12px;
  margin-bottom: 0;
  border: 1px solid #1f1f1f;
  border-radius: 5px;
  color: white;
  background-color: var(--secondary-bg);
  padding: 12px;
  padding-inline: 16px;
}

.field.input:focus-visible {
  outline: none;
}

.address-footer-text {
  line-height: 1.3; /* Adjust line-height to reduce space between lines */
}

.phone-footer-text{
  padding-top: 10px;
}

.social-icon-link {
  display: inline-flex;
  padding-left: 0;
  margin-left: 0px;
  padding: 4px;
  border: 1px solid transparent;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.social-icon-link:hover {
  border-color: var(--primary-light);
  box-shadow: 0 0 10px var(--primary-light);
  transform: translateY(-2px);
}

.social-icon-link img {
  width: 1.5rem;
  height: 1.5rem;
  transition: all 0.3s ease;
}

.social-icon-link:hover img {
  filter: brightness(1.2);
}